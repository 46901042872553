import { useEffect, useState } from "react";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { Button, Col, Container, Row } from "reactstrap";
import { firestore } from "./firebase";
import firebase from "firebase/app";
import logos1 from "./img/logos1.png";
import logos2 from "./img/logos2.png";
import logos3 from "./img/logos3.png";
import logos4 from "./img/logos4.png";
import logos5 from "./img/logos5.png";
import logos8 from "./img/logos8.svg";
import logos7 from "./img/logos7.png";
import logos9 from "./img/logos9.png";
import festivalLogo from "./img/festival-logo.png";
import natureculture from "./img/natureculture.png";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

export type FestivalEvent = {
  start: firebase.firestore.Timestamp | Date;
  title: string;
  programmText: string;
  teaser: string;
  description: string;
  picturePath: string;
  photoCopyright: string;
  id?: string;
};

const datesAreOnSameDay = (first: Date, second: Date) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

const wThursday = new Date("2024-06-20");
const wFriday = new Date("2024-06-21");
const wSaturday = new Date("2024-06-22");
const wSunday = new Date("2024-06-23");

const friday = new Date("2024-06-28");
const saturday = new Date("2024-06-29");
const sunday = new Date("2024-06-30");

const getDay = (date: Date) => {
  if (datesAreOnSameDay(date, wThursday)) {
    return "wThursday";
  }
  if (datesAreOnSameDay(date, wFriday)) {
    return "wFriday";
  }
  if (datesAreOnSameDay(date, wSaturday)) {
    return "wSaturday";
  }
  if (datesAreOnSameDay(date, wSunday)) {
    return "wSunday";
  }

  if (datesAreOnSameDay(date, friday)) {
    return "friday";
  }
  if (datesAreOnSameDay(date, saturday)) {
    return "saturday";
  }
  if (datesAreOnSameDay(date, sunday)) {
    return "sunday";
  }

  return "invalid";
};

const Programm: React.FC<{ onclick: (e: any) => void }> = ({ onclick }) => {
  const history = useHistory();
  const [events] = useCollectionDataOnce<FestivalEvent>(
    firestore.collection("/festivalevents2024").orderBy("start", "asc")
  );
  const [eventsByDay, setEventsByDay] = useState<{
    [day: string]: FestivalEvent[];
  }>();
  const [staticBg, setStaticBg] = useState(false);

  useEffect(() => {
    console.log("EVENTS", events);
    if (events && events.length > 0) {
      const eventsByMonth = events.reduce(
        (acc: { [day: string]: FestivalEvent[] }, event: FestivalEvent) => {
          if (event.start instanceof firebase.firestore.Timestamp) {
            event.start = event.start.toDate();
          }

          const day = getDay(event.start);
          if (acc[day]) {
            acc[day].push(event);
          } else {
            acc[day] = [event];
          }
          return acc;
        },
        {}
      );
      // eventsByMonth["wThursday"] = eventsByMonth["sunday"].slice(0, 1);
      // eventsByMonth["wFriday"] = eventsByMonth["friday"].slice(0, 1);
      // eventsByMonth["wSaturday"] = eventsByMonth["saturday"].slice(0, 1);
      // eventsByMonth["wSunday"] = eventsByMonth["sunday"].slice(0, 1);

      setEventsByDay(eventsByMonth);
    }
  }, [events]);

  const getEventElement = (events: FestivalEvent[]) =>
    events.map((e) => (
      <Col
        sm={12}
        style={{ cursor: "pointer" }}
        onClick={(ev) => {
          ev.stopPropagation();
          history.push(`/fm/${e.id}`);
        }}
      >
        {(e.start as Date).toLocaleTimeString("de-DE", {
          hour: "2-digit",
          minute: "2-digit",
        })}
        <div
          className="programm-element"
          dangerouslySetInnerHTML={{ __html: e.programmText }}
        ></div>
        <br />
      </Col>
    ));
  const onContentScroll: React.UIEventHandler<HTMLDivElement> = (event) => {
    const container = event.currentTarget;
    setStaticBg(container.scrollTop > 150);
  };

  return (
    <div className="programm" onClick={onclick} onScroll={onContentScroll}>
      <div
        className="festival-static-bg"
        style={{ visibility: staticBg ? "visible" : "hidden" }}
      >
        <img
          src={festivalLogo}
          alt=""
          style={{ width: "100%", height: "100%" }}
        />
      </div>
      <Container className="programm-container" fluid>
        <Row>
          <Col className="programm-head" sm={12}>
            <h2>
              Stimme X <br />
              Festival für Zeitgenössisches
              <br />
              Musiktheater
            </h2>

            <h2 style={{ bottom: 0, position: "absolute" }}>
              28. - 30. Juni 2024
              <br />
              Lichthof Theater
              <br />
              Hamburg
            </h2>
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col sm={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",

                maxWidth: "90vw",
                gap: "1rem",
                flexWrap: "wrap",
              }}
            >
              <div
                className="bubble"
                onClick={(ev) => {
                  ev.stopPropagation();
                  history.push(`/fm/c/about`);
                }}
              >
                about
              </div>
              <div
                className="bubble"
                onClick={(ev) => {
                  ev.stopPropagation();
                  history.push(`/fm/c/cooperations`);
                }}
              >
                cooperations
              </div>
            </div>
          </Col>
          <Col sm={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",

                maxWidth: "90vw",
                gap: "1rem",
                flexWrap: "wrap",
              }}
            >
              <div
                className="bubble"
                onClick={(ev) => {
                  ev.stopPropagation();
                  history.push(`/fm/c/voice`);
                }}
              >
                voice
              </div>
              <div
                className="bubble"
                onClick={(ev) => {
                  ev.stopPropagation();
                  history.push(`/fm/c/body`);
                }}
              >
                body
              </div>
              <div
                className="bubble"
                onClick={(ev) => {
                  ev.stopPropagation();
                  history.push(`/fm/c/natureculture`);
                }}
              >
                natureculture
              </div>
            </div>
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col sm={12}>
            <h2>Festivalprogramm</h2>
            <br />
            <br />
          </Col>

          <Col lg={4} className="programm-day-col">
            <Col sm={12}>
              <h5>Donnerstag, 20.6.</h5>
              <hr />
            </Col>
            {eventsByDay &&
              eventsByDay["wThursday"] &&
              getEventElement(eventsByDay["wThursday"])}
          </Col>
          <Col lg={4} className="programm-day-col">
            <Col sm={12}>
              <h5>Freitag, 21.6.</h5>
              <hr />
            </Col>
            {eventsByDay &&
              eventsByDay["wFriday"] &&
              getEventElement(eventsByDay["wFriday"])}
          </Col>
          <Col lg={4}></Col>
          <Col lg={4} className="programm-day-col">
            <Col sm={12}>
              <h5>Samstag, 22.6.</h5>
              <hr />
            </Col>
            {eventsByDay &&
              eventsByDay["wSaturday"] &&
              getEventElement(eventsByDay["wSaturday"])}
          </Col>

          <Col lg={4} className="programm-day-col">
            <Col sm={12}>
              <h5>Sonntag, 23.6.</h5>
              <hr />
            </Col>
            {eventsByDay &&
              eventsByDay["wSunday"] &&
              getEventElement(eventsByDay["wSunday"])}
          </Col>
          <Col lg={4}></Col>

          <Col lg={4} className="programm-day-col">
            <Col sm={12}>
              <h5>Freitag, 28.6.</h5>
              <hr />
            </Col>
            {eventsByDay &&
              eventsByDay["friday"] &&
              getEventElement(eventsByDay["friday"])}
          </Col>

          <Col lg={4} className="programm-day-col">
            <Col sm={12}>
              <h5>Samstag, 29.6.</h5>
              <hr />
            </Col>
            {eventsByDay &&
              eventsByDay["saturday"] &&
              getEventElement(eventsByDay["saturday"])}
          </Col>
          <Col lg={4} className="programm-day-col">
            <Col sm={12}>
              <h5>Sonntag, 30.6.</h5>
              <hr />
            </Col>
            {eventsByDay &&
              eventsByDay["sunday"] &&
              getEventElement(eventsByDay["sunday"])}
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <hr />
            <FontAwesomeIcon
              icon={faFacebook}
              onClick={() => window.open("https://www.facebook.com/StimmeX")}
              className="fb-icon-mobile"
              style={{
                height: "3vh",
                width: "auto",
                zIndex: 1,
                color: "white",
                cursor: "pointer",
              }}
            />
            <FontAwesomeIcon
              icon={faInstagram}
              onClick={() => window.open("https://www.instagram.com/stimme_x/")}
              className="insta-icon-mobile"
              style={{
                marginLeft: "1rem",
                height: "3vh",
                width: "auto",
                zIndex: 1,
                color: "white",
                cursor: "pointer",
              }}
            />
          </Col>
          <Col sm={12}>Gefördert durch:</Col>
          <Col sm={12}>
            <br />
          </Col>
          <Col sm={12}>
            <div className="flex-container">
              <div
                className="flex-item logo logo1"
                style={{ cursor: "pointer" }}
                onClick={() => window.open("https://www.kulturstiftung-hh.de/")}
              >
                <img src={logos1} alt="1" className="img-responsive" />
              </div>
              {/* <div className="flex-item">
                        <img src={logos6} alt="1" className="img-responsive"  style={{filter:'invert(100%)'}}/>
                    </div> */}
              <div
                className="flex-item logo logo2"
                style={{ cursor: "pointer" }}
                onClick={() => window.open("https://www.fonds-daku.de/")}
              >
                <img
                  src={logos2}
                  alt="2"
                  className="img-responsive inverted-color"
                />
              </div>

              <div
                className="flex-item logo logo3"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open("https://www.claussen-simon-stiftung.de/de/")
                }
              >
                <img src={logos3} alt="3" className="img-responsive" />
              </div>

              <div
                className="flex-item logo logo4"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open(
                    "https://www.kulturstiftung-hh.de/betreute-stiftungen/berit-und-rainer-baumgarten-stiftungsfonds.html"
                  )
                }
              >
                <img src={logos4} alt="3" className="img-responsive" />
              </div>
              {/* <div className="flex-item">
                        <img src={logos7}alt="5"className="img-responsive" />
                    </div> */}
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <br />
          </Col>
          <Col sm={12}>Kooperationen:</Col>
          <Col sm={12}>
            <br />
          </Col>
          <Col sm={12}>
            <div className="flex-container">
              {/* <div className="flex-item">
                <img src={logos4} alt="4" className="img-responsive" />
              </div> */}
              <div
                className="flex-item logo logo5"
                style={{ cursor: "pointer" }}
                onClick={() => window.open("https://orbit.cologne/")}
              >
                <img
                  src={logos8}
                  alt="5"
                  className="img-responsive inverted-color"
                />
              </div>

              <div
                className="flex-item logo logo9"
                style={{ cursor: "pointer" }}
                onClick={() => window.open("https://www.hfmt-hamburg.de/")}
              >
                <img src={logos9} alt="3" className="img-responsive" />
              </div>
              <div
                className="flex-item logo logo6"
                style={{ cursor: "pointer" }}
                onClick={() => window.open("https://www.lichthof-theater.de/")}
              >
                <img src={logos5} alt="5" className="img-responsive" />
              </div>
            </div>
          </Col>

          <Col sm={12}>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Programm;
