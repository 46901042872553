import React, { useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { Modal, ModalBody, ModalHeader, ModalProps } from "reactstrap";
import { About } from "./festivalContent/About";
import { Body } from "./festivalContent/Body";
import { Cooperations } from "./festivalContent/Cooperaions";
import { NatureCulture } from "./festivalContent/Natureculture";
import { Voice } from "./festivalContent/Voice";

import FestivalEvent from "./FestivalEvent";

export const FestivalModal = ({
  isOpen,
  toggle,
}: {
  isOpen: boolean;
  toggle: ModalProps["toggle"];
}) => {
  const [name, setName] = useState("About");
  const headerToggle = (e: React.MouseEvent<any, MouseEvent>) => {
    if (toggle) {
      toggle(e as any);
    }
  };

  const history = useHistory();
  return (
    <Modal size="xl" isOpen={isOpen} toggle={toggle} scrollable>
      <ModalHeader className="festival-modal-header" toggle={headerToggle}>
        <h3 style={{ cursor: "pointer" }} onClick={() => history.goBack()}>
          {"<< "}zurück
        </h3>
      </ModalHeader>
      <ModalBody className="festival-modal-body">
        <Switch>
          <Route path="/fm/c/natureculture">
            <NatureCulture setName={setName} />
          </Route>
          <Route path="/fm/c/about">
            <About setName={setName} />
          </Route>

          <Route path="/fm/c/cooperations">
            <Cooperations setName={setName} />
          </Route>
          <Route path="/fm/c/Body">
            <Body setName={setName} />
          </Route>
          <Route path="/fm/c/voice">
            <Voice setName={setName} />
          </Route>
          <Route path="/fm/:eventId">
            <FestivalEvent setName={setName} />
          </Route>
        </Switch>
      </ModalBody>
    </Modal>
  );
};
