import { Col, Container, Row } from "reactstrap";
import trennlinie from "../img/line-headline.svg";
import {
  useCollectionDataOnce,
  useCollectionOnce,
  useDocument,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import { firestore } from "../firebase";
import { Fragment } from "react";
import { StimmexText } from "../Types";

export const Voice: React.FC<{ setName: (name: string) => void }> = ({
  setName,
}) => {
  setName("Voice");
  const [subsections] = useCollectionDataOnce<StimmexText>(
    firestore
      .collection(`/texte`)
      .where("section", "==", "voice")
      .orderBy("index", "asc")
  );

  return (
    <Container>
      <Col sm="12">
        <h1 className="subsection">
          {" "}
          <img
            src={trennlinie}
            className="svg-icon svg-baseline inverted-color"
          />{" "}
          Voice
        </h1>
      </Col>

      <Col sm="12">
        <img
          className="image-responsive"
          src="https://firebasestorage.googleapis.com/v0/b/stimmex-ec282.appspot.com/o/events%2Fimages%2FSTIMME_X_FESTIVAL_small.jpg?alt=media&token=043d5ee3-13b9-4f46-acb9-92841d92798c"
          style={{
            maxHeight: "500px",
            width: "auto",
            maxWidth: "90vw",
            objectFit: "scale-down",
          }}
        ></img>
        {/* <p> © Simone Scardovelli</p> */}

        {/* <img src="https://firebasestorage.googleapis.com/v0/b/stimmex-ec282.appspot.com/o/events%2Fimages%2Ffestival-log.png?alt=media&token=00e1224a-c8d5-42d5-b354-9ffb39b0bd6e" style={{width:"40%", height:"auto", float:"right", paddingLeft:"5px"}} /><p>Testing&nbsp;</p> */}

        {subsections?.map((s) => (
          <div dangerouslySetInnerHTML={{ __html: s.content }} />
        ))}
      </Col>

      <Row></Row>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </Container>
  );
};
